import {
  CreateOrderAndChargeDocument,
  EcomCheckoutV1CreateOrderAndChargeRequestInput,
  EcomCheckoutV1CreateOrderAndChargeResponse,
  EcomCheckoutV1RemoveCouponRequestInput,
  EcomCheckoutV1RemoveGiftCardRequestInput,
  EcomCheckoutV1RemoveLineItemsRequestInput,
  EcomCheckoutV1UpdateCheckoutRequestInput,
  RemoveCouponDocument,
  RemoveLineItemDocument,
  UpdateCheckoutDocument,
  RemoveGiftCardDocument,
  GetCheckoutDocument,
  GetCheckoutOldDocument,
} from '../../../gql/graphql';
import {HttpError} from '@wix/http-client';
import {IGraphqlClient} from '@wix/graphql-client';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {createAutoGraphqlClient} from '../../utils/autoGraphqlClient';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {getMultilingualHeader} from '../../utils/ambassador.utils';
import {GraphQLFormattedError} from 'graphql';
import {TypedDocumentNode} from '@graphql-typed-document-node/core';
import {Checkout} from '../../../types/checkoutApp.types';
import {SPECS} from '../../../common/constants';

interface ParamsGenerationPrerequisites {
  siteStore: SiteStore;
  flowAPI: ControllerFlowAPI;
  currency?: string;
}

interface ConstructorParams {
  siteStore: SiteStore;
  flowAPI: ControllerFlowAPI;
  currency?: string;
}

export class AutoGraphqlApi {
  private readonly autoGraphqlClient: IGraphqlClient;
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  public readonly currency?: string;

  constructor({flowAPI, siteStore, currency}: ConstructorParams) {
    this.flowAPI = flowAPI;
    this.siteStore = siteStore;
    this.currency = currency;
    this.autoGraphqlClient = createAutoGraphqlClient(flowAPI.essentials.httpClient);
  }

  private async executeGraphqlMutation<M, V>(mutation: TypedDocumentNode<M, {input: V}>, input: V) {
    const params = getParams({siteStore: this.siteStore, flowAPI: this.flowAPI, currency: this.currency});

    const {data, errors} = await this.autoGraphqlClient.query(mutation, {input}, params);
    this.handleGraphqlErrors(data, errors);

    return data;
  }

  private handleGraphqlErrors(data: any, gqlErrors: GraphQLFormattedError[] | undefined): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const checkout = (data?.checkout ?? data?.checkoutMutation?.checkout) as Checkout;
    if (checkout && checkout?.lineItems?.length === 0) {
      return;
    }

    if (gqlErrors && gqlErrors.length > 0) {
      const httpError = this.mapGraphqlErrorToHttp(gqlErrors);
      /* istanbul ignore else */
      if (httpError) {
        throw httpError;
      }
    }
    return undefined;
  }

  private ensureCheckoutData<T>(data: {checkoutMutation?: T}) {
    /* istanbul ignore next */
    if (!data.checkoutMutation) {
      /* istanbul ignore next */
      throw new Error('Missing checkout data after mutation');
    }
    return {data: data.checkoutMutation};
  }

  private mapGraphqlErrorToHttp(gqlErrors: GraphQLFormattedError[]): HttpError | null {
    const checkoutError = gqlErrors.find((err) => err.path?.includes('checkout'));
    const error = /* istanbul ignore next */ checkoutError || gqlErrors[0];
    /* istanbul ignore else */
    if (error?.extensions) {
      return {
        response: {
          data: {message: error.message, details: error.extensions},
        },
      } as HttpError;
    } else {
      return null;
    }
  }

  public async getCheckout(id: string) {
    const params = getParams({siteStore: this.siteStore, flowAPI: this.flowAPI, currency: this.currency});
    const queryWithLayoutConfig = this.flowAPI.experiments.enabled(SPECS.AddLayoutConfigToLineItemsAutoGql);
    const {data, errors} = await this.autoGraphqlClient.query(
      queryWithLayoutConfig ? GetCheckoutDocument : GetCheckoutOldDocument,
      {checkoutId: id},
      params
    );
    this.handleGraphqlErrors(data, errors);
    return {data};
  }

  public async updateCheckout(input: EcomCheckoutV1UpdateCheckoutRequestInput) {
    const data = await this.executeGraphqlMutation(UpdateCheckoutDocument, input);
    return this.ensureCheckoutData(data);
  }

  public async removeCoupon(input: EcomCheckoutV1RemoveCouponRequestInput) {
    const data = await this.executeGraphqlMutation(RemoveCouponDocument, input);
    return this.ensureCheckoutData(data);
  }

  public async removeGiftCard(input: EcomCheckoutV1RemoveGiftCardRequestInput) {
    const data = await this.executeGraphqlMutation(RemoveGiftCardDocument, input);
    return this.ensureCheckoutData(data);
  }

  public async removeLineItem(input: EcomCheckoutV1RemoveLineItemsRequestInput) {
    const data = await this.executeGraphqlMutation(RemoveLineItemDocument, input);
    return this.ensureCheckoutData(data);
  }

  public async createOrderAndCharge(
    input: EcomCheckoutV1CreateOrderAndChargeRequestInput
  ): Promise<{data: EcomCheckoutV1CreateOrderAndChargeResponse}> {
    const data = await this.executeGraphqlMutation(CreateOrderAndChargeDocument, input);

    return {
      data: {
        orderId: data.checkoutMutation?.orderId ?? undefined,
        subscriptionId: data.checkoutMutation?.subscriptionId ?? undefined,
        paymentGatewayOrderId: data.checkoutMutation?.paymentGatewayOrderId,
        paymentResponseToken: data.checkoutMutation?.paymentResponseToken,
      },
    };
  }
}

function getParams(paramsGenerationPrerequisites: ParamsGenerationPrerequisites) {
  /* istanbul ignore else */
  if (paramsGenerationPrerequisites) {
    return {headers: getHeaders(paramsGenerationPrerequisites)};
  }
  /* istanbul ignore next */
  return undefined;
}

function getHeaders({currency, flowAPI, siteStore}: ParamsGenerationPrerequisites) {
  const currencyHeader = currency ? {'x-wix-currency': currency} : {};
  const multilingualHeader = getMultilingualHeader(flowAPI, siteStore);
  return {...currencyHeader, ...multilingualHeader};
}
